import React, { memo } from 'react'
import { Modal, Button } from 'antd'

import styles from './style.module.scss'


const SplashScreen = memo(({ open, toggleOpenCloseModal }) => {
  return (
    <Modal
      title=""
      width="100%"
      visible={open}
      onOk={toggleOpenCloseModal}
      onCancel={toggleOpenCloseModal}
      footer={null}
      wrapClassName={styles.container}
    >
      <div className={styles.modalcontainer}>
        <div className={styles.logoContainer}>
          <img src="resources/images/logo-white.png" alt="Adzy" />
        </div>
        <div className={styles.ctaContainer}>
          <Button className={`action-button ${styles.actionButton}`} onClick={toggleOpenCloseModal}>
            Create Now
          </Button>
        </div>
        <h1 className={styles.title}>We create ads that grow your business</h1>
        <div className={styles.alignLeft}>

          We set up Adzy to help brands that make our lives easier during social isolation to advertise to customers stuck at home in lockdown. These include:
<br />
          <br />
&#10003; Food delivery services<br />
&#10003; Mental health & mindfulness therapists<br />
&#10003; Personal trainers & yoga instructors<br />
&#10003; Tutors & educators for our children<br />
          <br />
You can create an ad with the Adzy app for free and post it to your Facebook page for your followers to see. Or, if you want to grow your business with paid advertising on Facebook, email us and we will help you place your ad.
<br />
While people are having to stay home during the pandemic we offer the entire service from Adzy for free.
<br />
The only money you spend will be on Facebook sending your ad out to the right audiences, and this is up to you.
<br />
So go ahead, sign-up for free and have some fun!
        <p className="user-signature">
          Jasmine Montgommery<br />
          CEO Team Adzy
        </p> 
        </div>
        {/* <p>
          We&lsquo;re a <i>Preferred Google and Facebook Marketing Partner.</i> That&lsquo;s a tier above the rest. And as such, we have
          access to exclusive product updates, advanced training, and office hours with Facebook itself. The Facebook
          and Google teams also grant LADDER prioritized issue resolution, engineering solutions, and creative
          consultation.
        </p>
        <p>
          And with secret performance data across more than 8,000 marketing experiments and growth tactics, we&lsquo;ve built
          a creative analysis software powered by machine learning. This gives us a special edge as we can show you
          performance patterns in the granular ad image/copy facets that drive your ROI - at scale.
        </p>
        <p className="user-signature">
          Jasmine Montgommery<br />
          CEO Team Adzy
        </p> */}

      </div>
    </Modal>
  )
})

export default SplashScreen

