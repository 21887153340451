/* eslint-disable */
import React, { Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import Loader from 'components/LayoutComponents/Loader';
import UserLoader from 'components/UserComponents/Loader';
import PublicLayout from './Public';
import LoginLayout from './Login';
import MainLayout from './Main';
import UserLayout from './User';
import { gtmLogPageChanged } from 'services/utils';

const Layouts = {
	public: PublicLayout,
	login: LoginLayout,
	main: MainLayout,
	user: UserLayout
};

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
	previousPath = '';

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		const { prevLocation } = prevProps;
		if (location !== prevLocation) {
			//window.scrollTo(0, 0);
			const { pathname, search } = location;
			if(this.previousPath == pathname) return;
			this.previousPath = pathname;
			gtmLogPageChanged(pathname);
		}
	}

	render() {
		const { children, location: { pathname, search }, user } = this.props;

		// NProgress Management
		// const currentPath = pathname + search;
		// if (currentPath !== this.previousPath) {
		// 	NProgress.start();
		// }

		// setTimeout(() => {
		// 	NProgress.done();
		// 	this.previousPath = currentPath;
		// }, 300);

		if (!user.role || user.role === undefined) {
			user.role = [ 'user' ];
		}

		// Layout Rendering
		const getLayout = () => {
			if (pathname === '/' || /^\/(debug)(?=\/|$)/i.test(pathname)) {
				return 'public';
			}
			if (/^\/(user|login|register|forgot-password|verification)(?=\/|$)/i.test(pathname)) {
				return 'login';
			}
			return !user.role.includes('user') ? 'main' : 'user';
		};

		const Container = Layouts[getLayout()];
		const isUserAuthorized = user.authorized;
		const isUserAdmin = user.role.includes('admin');
		const isUserLoading = user.loading;
		const isLoginLayout = getLayout() === 'login';
		const layout = getLayout();

		const BootstrappedLayout = () => {
			// show loader when user in check authorization process, not authorized yet and not on login pages
			if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
				return isUserAdmin ? <Loader /> : <UserLoader />;
			}
			if (layout == 'public') {
				return <Container>{children}</Container>;
			}		
			// redirect to login page if current is not login page and user not authorized
			if (!isLoginLayout && !isUserAuthorized) {
				return <Redirect to="/login" />;
			}
			// redirect to main dashboard when user on login page and authorized
			if (isLoginLayout && isUserAuthorized) {
				return <Redirect to="/dashboard" />;
			}

			// in other case render previously set layout
			return <Container>{children}</Container>;
		};

		return (
			<Fragment>
				<Helmet titleTemplate="Adzy | %s" title="Adzy" />
				{BootstrappedLayout()}
			</Fragment>
		);
	}
}

export default IndexLayout;
