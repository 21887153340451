import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { apiCall } from 'services/utils';

export function* GET_MYADS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'GET',
      url: 'Campaign/myAds',
    }),
  );

  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response.value,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_MYADS, GET_MYADS)]);
}
