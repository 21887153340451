const actions = {
  RESET: 'user/RESET',
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUBMIT: 'user/FORGOT_PASSWORD_SUBMIT',
  UPDATE_ACCOUNT: 'user/UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SECTOR: 'user/UPDATE_ACCOUNT_SECTOR',
  UPDATE_ACCOUNT_COMPANY: 'user/UPDATE_ACCOUNT_COMPANY',
  SEND_FEEDBACK: 'user/SEND_FEEDBACK',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  VERIFY_CODE: 'user/VERIFY_CODE',
  APPROVE_AGGREMENT: 'user/APPROVE_AGGREMENT',

  CREATE_USER: 'user/CREATE_USER',
  UPDATE_USER: 'user/UPDATE_USER',
  DELETE_USER: 'user/DELETE_USER',
}

export default actions
