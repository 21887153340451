import React from 'react';
import classNames from 'classnames';
import styles from './style.module.scss';

const Loader = ({ expand }) => (
  // <div
  //   className={classNames(styles.loader, {
  //     [styles.hidden]: !spinning,
  //     [styles.fullScreen]: fullScreen,
  //   })}
  // />
  <div
    className={classNames(styles.loaderWrapper, {
      [styles.expand]: expand,
    })}>
    <div className={styles.loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
