import actions from './actions'

const initialState = {
  features: [],
  loading: false,
  setupIntent: null,
  cards: [],
  paymentHistory: [],
  subscriptionList: [],
  invoiceList: [],
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
