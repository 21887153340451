import { all, takeEvery, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { API } from 'aws-amplify';
import axios from 'axios';
import actions from './actions';
import { apiCall, convertModelToFormData, processError, processResponse } from 'services/utils';

// list(data) {
//   return API.post('NODE_SERVICE_API', 'Campaign/ad/image/user/list', { body: data })
//     .then((response) => response)
//     .catch((err) => err);
// },
// create(data) {
//   return API.post('NODE_SERVICE_API', 'Campaign/ad/image/create', { body: data })
//     .then((response) => response)
//     .catch((err) => err);
// },
// resize(data) {
//   return API.post('NODE_SERVICE_API', 'Campaign/ad/image/resize', { body: data })
//     .then((response) => response)
//     .catch((err) => err);
// },
// upsertCampaign(data) {
//   return API.post('NODE_SERVICE_API', 'Campaign/user/upsert', { body: data })
//     .then((response) => response)
//     .catch((err) => err);
// },

export function* LIST_USERIMAGE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingList: true,
    },
  });
  const response = yield call(() =>
    apiCall({
      method: 'GET',
      url: 'Campaign/ad/image/user/list',
    }),
  );
  if (response.success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        images: response.value,
      },
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingList: false,
    },
  });
}

export function* UPLOAD_USERIMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  var formData = convertModelToFormData(payload, '');
  const response = yield call(() =>
    apiCall({
      url: 'Campaign/ad/image/create',
      data: formData,
      timeout: 600000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  );
  if (response.success) {
    yield put({
      type: actions.LIST_USERIMAGE,
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        displayImageUpload: false,
        uploadedImage: response.value
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* UPLOAD_USERIMAGE2({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const response = yield call(
    (data) => API.post('NODE_SERVICE_API', 'Campaign/ad/image/user/list', { body: data, headers: {} }),
    payload,
  );
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response,
      },
    });
  } else {
    notification.error({
      message: 'Could not load data',
      description: 'System could not communicate properly with the endpoints',
    });
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPLOAD_USERIMAGE, UPLOAD_USERIMAGE), takeEvery(actions.LIST_USERIMAGE, LIST_USERIMAGE)]);
}
