import actions from './actions';

const initialState = {
  dataAdSet1: [],
  dataAdSet2: [],
  adSetNo: 0,
  campaign: {},
  primaryTexts: [],
  data: [],
  favorites: [],
  myliveads: [],
  mysavedads: [],
  loading: false,
  imageDebugInfo: false,
  actionStatus: '',
};

export default function adsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return { ...initialState };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
