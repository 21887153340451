import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';

import Loader from 'components/LayoutComponents/Loader';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';

const loadable = (loader) => //Loader;
	Loadable({
		loader,
		delay: false,
		loading: () => <Loader />
	});

const routes = [
	// Godin Routes
	{
		path: '/debug',
		component: loadable(() => import('pages/debug')),
		exact: true
	},
	{
		path: '/login',
		component: loadable(() => import('pages/user/login')),
		exact: true
	},
	{
		path: '/register',
		component: loadable(() => import('pages/user/register')),
		exact: true
	},
	{
		path: '/forgot-password',
		component: loadable(() => import('pages/user/forgot')),
		exact: true
	},
	{
		path: '/verification',
		component: loadable(() => import('pages/user/verification')),
		exact: true
	},

	{
		path: '/dashboard',
		component: loadable(() => import('pages/home'))
	},
	{
		path: '/terms',
		component: loadable(() => import('pages/terms')),
		exact: true,
	},
	// users routes
	{
		path: '/welcome',
		component: loadable(() => import('pages/welcome'))
	},
	{
		path: '/users/create-ad',
		component: loadable(() => import('pages/create-ad'))
	},
	{
		path: '/users/feedback',
		component: loadable(() => import('pages/user/feedback'))
	},
	{
		path: '/users/plans/:action?',
		component: loadable(() => import('pages/plans/index'))
	},
	{
		path: '/users/payments/:action',
		component: loadable(() => import('pages/payments/index'))
	},
	{
		path: '/users/payments',
		component: loadable(() => import('pages/payments/index'))
	},
	{
		path: '/users/payment-methods',
		component: loadable(() => import('pages/payment-cards/index'))
	},
	{
		path: '/users/payment-history',
		component: loadable(() => import('pages/payment-history/index'))
	},
	{
		path: '/users/plan-detail',
		component: loadable(() => import('pages/payment-plan-detail/index'))
	},
	{
		path: '/users/image',
		component: loadable(() => import('pages/user-image/index'))
	},
	{
		path: '/users/change-password',
		component: loadable(() => import('pages/change-password/index'))
	},
	{
		path: '/campaign/:campaignId/:mode',
		component: loadable(() => import('pages/campaign/edit'))
	},
	{
		path: '/campaign/:campaignId',
		component: loadable(() => import('pages/campaign/edit'))
	},
	{
		path: '/campaign/new',
		component: loadable(() => import('pages/campaign/edit'))
	},
	{
		path: '/your-details',
		component: loadable(() => import('pages/your-details/index'))
	},
];
class Router extends React.Component {
	render() {
		const { history } = this.props;
		return (
			<ConnectedRouter history={history}>
				<IndexLayout>
					<Switch>
						<Route exact path="/" render={() => <Redirect to="/login" />} />
						{routes.map((route) => (
							<Route path={route.path} component={route.component} key={route.path} exact={route.exact}  />
						))}
						<Route component={NotFoundPage} />
					</Switch>
				</IndexLayout>
			</ConnectedRouter>
		);
	}
}

export default Router;
